var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding1",staticStyle:{"background":"#f4f5fa","min-height":"80vh"}},[(_vm.info==true)?_c('v-card',{staticClass:"elevation-0 pb-6 px-7",staticStyle:{"background":"transparent"}},[_c('v-card-text',{staticClass:"py-3 px-6",staticStyle:{"background":"#1976d1","color":"white","border-radius":"5px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white","small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" Lorem ipsum dolor sit amet consectetur adipiscing elit massa imperdiet, mauris tortor platea fringilla sodales ac magna sociosqu, natoque non rutrum nec auctor vestibulum congue eget. "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"position":"absolute!important","top":"7px","right":"30px"},attrs:{"color":"white","small":""},on:{"click":function($event){_vm.info=false}}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card',{staticClass:"elevation-0 pb-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticClass:"px-6",staticStyle:{"background":"white"}},[_vm._v("Influencers "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"pt-0 pb-2",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}}),_c('v-select',{staticClass:"ml-6",staticStyle:{"max-width":"150px"},attrs:{"label":"Status","clearable":"","items":_vm.status,"item-text":"label","item-value":"value"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0 px-8",attrs:{"search":_vm.search,"expand-icon":"","headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{on:{"click":function($event){return _vm.sendNotification(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-email ")]),_vm._v(" Enviar invitación a APP ")],1)],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.last)+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active=='Activo')?_c('v-chip',{staticStyle:{"background":"#4caf5026!important"},attrs:{"x-small":"","color":"success","outlined":""}},[_vm._v("Activo")]):(item.is_active=='Inactive')?_c('v-chip',{staticStyle:{"background":"#ff52521f!important"},attrs:{"x-small":"","color":"error","outlined":""}},[_vm._v("Inactivo")]):_vm._e()]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen influencers registrados. ")])],2)],1),_c('v-snackbar',{staticStyle:{"margin-bottom":"30px"},attrs:{"color":_vm.snackbar.color,"bottom":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }